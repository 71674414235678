<template>
  <div class="card card-custom">
    <div class="card-header">
      <v-row>
           <div class="col-lg-4  col-sm-12 mb-3 mt-2" >
                <div class="card-title d-flex align-items-center">
                  <span class="card-icon">
                    <i class="flaticon2-chat-1 text-primary "></i>
                  </span>
                  <h3 class="card-label ml-2 font-poppins">Table Reservation </h3>
                </div>
            </div>
            <div class="col-lg-8  col-sm-12 mb-3 align-items-center justify-content-end" >

              <v-row class="align-items-center justify-content-end mt-3" no-gutters>
                  <div class="col-lg-2 col-sm-12 d-flex justify-end px-2 clear-filters"  v-if="isAnyFilterApplied" >
                    <span class="cursor-pointer text-primary" @click="clearFilters">Clear Filters</span>
                  </div>
                  <div  class=" col-lg-4 col-sm-12 mb-2 px-2">
                    <v-text-field
                      v-model="business"
                      outlined
                      dense
                      hide-details
                      placeholder="Search by Business  Name"
                    ></v-text-field>
                  </div>
                  <div  class="col-lg-3 col-sm-12 mb-2 px-2">
                    <v-select
                      v-model="filterStatus"
                      :items="statuses"
                      outlined
                      dense
                      hide-details
                      placeholder="Searh by Status"
                    ></v-select>
                  </div>
                  <div  class=" col-lg-1 col-sm-12 mb-2 px-2">
                    <v-btn elevation="2" class="bg-primary text-light font-poppins"  @click="loadReservations">Search</v-btn>
                  </div>
      </v-row>
            </div>

      </v-row>
   
 
    </div>
    <div class="card-body p-0">
      <div class="row my-5 px-2">
        <div class="col-sm-12">
          <v-data-table
            :headers="headers"
            :items="reservations"
            :options.sync="options"
            :server-items-length="totalReservations"
            :loading="loading"
            class="elevation-1"
          >
          <template v-slot:item.business.name="{ item }">
      <!-- Customize the content here -->
      <!-- <span class="custom-business-name">{{ item.business.name }}</span> -->
      <v-chip v-if="item.business.name" class="bg-primary text-light font-weight-bolder" @click="goToStore(item)">
                {{ item.business.name }}
              </v-chip>
    </template>
            <template v-slot:item.actions="{ item }">
              <v-icon mediam class="mr-2 text-info" @click="edit(item.id)">
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:footer.page-text>
              {{ `Displaying ${from} - ${to} of ${totalReservations} items` }}
            </template>
          </v-data-table>
          <v-pagination
            v-model="options.page"
            :length="pages"
            :total-visible="7"
            @input="loadReservations"
          ></v-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "Reservations",
  data() {
    return {
      dialog: false,
      singleSelect: false,
      selected: [],
      business: "",
      filterStatus: "",
      headers: [
        { text: "Business Name", align: "left", sortable: false, value: "business.name" },
        { text: "First Name", align: "left", sortable: false, value: "first_name" },
        { text: "Last Name", align: "left", sortable: false, value: "last_name" },
        { text: "Email", align: "left", sortable: false, value: "email" },
        { text: "Phone", align: "left", sortable: false, value: "phone" },
        { text: "Date", align: "left", sortable: false, value: "date" },
        { text: "Persons", align: "left", sortable: false, value: "number_people" },
        { text: "Status", align: "left", sortable: false, value: "status" },
        { text: "Instructions", align: "left", sortable: false, value: "instructions" },
        { text: "Actions", align: "left", sortable: false, value: "actions" },
      ],
      reservations: [],
      totalReservations: 0,
      options: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
      },
      loading: false,
      statuses: ["Reserved", "Pending", "Accept", "Rejected"], // Example status options
    };
  },
  watch: {
    options: {
      handler() {
        this.loadReservations();
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Customer Table Reservations" }]);
    this.loadReservations();
  },
  computed: {
    isAnyFilterApplied() {
      return this.business !== "" || this.filterStatus !== "";
    },

    
    from: {
    get() {
      return (this.options.page - 1) * this.options.itemsPerPage + 1;
    },
    set(value) {
      // Update the options.page based on the new value
      const page = Math.ceil((value - 1) / this.options.itemsPerPage) + 1;
      this.$set(this.options, 'page', page);
    }
  },
    to() {
      return Math.min(this.options.page * this.options.itemsPerPage, this.totalReservations);
    },
    pages() {
      return Math.ceil(this.totalReservations / this.options.itemsPerPage);
    }
  },
  methods: {
    goToStore(business) {
    let storeUrl = 'https://' + business.store_name + '.getmefood.co.uk';
    if (business.custom_domain_verify == 'verified' && business.custom_domain) {
      storeUrl = 'https://' + business.custom_domain;
    }
    window.open(storeUrl, '_blank');
  },
    loadReservations() {
      this.loading = true;
      ApiService.post("admin/reservations", {
        business_name: this.business,
        status: this.filterStatus,
        per_page: this.options.itemsPerPage,
        page: this.options.page,
      }).then(({ data }) => {
        this.reservations = data.data;
        this.totalReservations = data.total;
        this.from = data.from;
        this.to = data.to;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    clearFilters() {
      this.business = "";
      this.filterStatus = "";
      this.options.page = 1; // Reset the page to 1 when clearing the filters
      this.loadReservations();
    },
    edit(id) {
      // Edit functionality
    },
  },
};
</script>

<style scoped>

.v-dialog__container {
  display: block !important;
}
.v-btn.icon {
  min-width: auto;
}
</style>
